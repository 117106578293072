exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-add-on-items-tsx": () => import("./../../../src/pages/add-on-items.tsx" /* webpackChunkName: "component---src-pages-add-on-items-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-corporate-events-mdx": () => import("./../../../src/pages/corporate-events.mdx" /* webpackChunkName: "component---src-pages-corporate-events-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pickup-delivery-tsx": () => import("./../../../src/pages/pickup-delivery.tsx" /* webpackChunkName: "component---src-pages-pickup-delivery-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

